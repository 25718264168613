<template>
  <div class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">
        {{title}}
      </p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">
        <slot name="description">
          Envie qualquer tipo de arquivo.
          <br>Arquivos podem ser públicos ou privados. <span class="text-negative">Nosso antivírus irá analisar o documento após upload.</span>
        </slot>
      </div>
    </div>
    <div @click="dropClickEvent"
         class="w-m-body e-input-modern flex items-center center justify-center cursor-pointer dropzone"
         :class="{'dropzoneEnter': dropzone}"
         @dragenter.prevent.stop="dropzone = true"
         @dragover.prevent.stop="dropzone = true"
         @drop.prevent.stop="inputListener"
         @dragleave.prevent.stop="dropzone = false"
    >
      <div class="text-center" v-if="!files.length">
        <div class="text-center text-3x m-b">
          <u-icon color="grey" name="cloud-upload-alt" type="fa" />
        </div>
        <div class="m-b-xs"><strong>Arraste e solte os arquivos aqui</strong></div>
        Ou clique para selecionar arquivos em seu computador
      </div>
      <u-list class="w-full" inset-separator v-else>

        <u-item v-for="(file, i) in files" :key="i">
          <u-item-side :icon="bindIcon(file.file.type)" icon-type="fa" color="grey" />
          <u-item-main :label="file.file.name" />
          <u-item-side style="max-width: 100px" v-if="permissions">
            <erp-select required :options="permissions" v-model="file.permissao" />
          </u-item-side>
          <u-item-side style="max-width: 120px" v-if="enableTitleFile">
            <erp-input required placeholder="Título" v-model="file.nome" />
          </u-item-side>
          <u-item-side style="max-width: 110px" v-if="enableValidade">
            <erp-input required placeholder="Validade" v-model="file.validade" v-mask="'##/##/####'" />
          </u-item-side>
          <u-item-side v-if="typeFileList">
            <erp-select required :options="tipos" v-model="file.tipo" />
          </u-item-side>
          <u-item-side v-if="file.done" right>
            <u-progress v-if="file.copying" :percentage="file.progress" :animate="true"
                        :color="file.progress < 85 ? 'grey' : 'positive'" height="10px" />
            <div v-else>
              <u-icon name="check" type="fa" color="positive" v-if="file.success"></u-icon>
              <u-btn @click.prevent.stop="upload(file)" v-else color="negative" size="sm"
                     label="Falha. Tentar novamente" />
            </div>
          </u-item-side>
          <u-item-side v-else>
            <u-btn @click.prevent.stop="removeFile(file)" flat rounded round icon="times" icon-type="fa" />
          </u-item-side>
          <!--{{file.name}}-->
        </u-item>
      </u-list>
      <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
             v-show="false">
    </div>
    <div class="w-m-footer">
      <e-row>
        <e-col>
          <u-btn @click="$refs.input.click()" type="submit" icon="upload" icon-type="fa" label="Adicionar Arquivo(s)"
                 color="blue"
                 class="b-radius-3px" />
        </e-col>
        <e-col class="text-right">
          <e-row>
            <e-col class="flex items-center">
              <erp-select size="2" placeholder="Selecione um Tipo padrão para tornar mais ágil o preenchimento" :options="tipos" v-model="tipoDefault" />
            </e-col>
            <e-col v-if="existsFilesToUpload">
              <u-btn @click="iniciaEnvios" type="submit" icon="paper-plane" icon-type="fa"
                     label="Enviar arquivos"
                     color="green"
                     class="b-radius-3px m-r_" />
            </e-col>
          </e-row>
          <!--<u-btn type="submit" label="Fechar" color="grey" @click="$uloc.window.close($root.wid)"
                 class="b-radius-3px" />-->
        </e-col>
      </e-row>
    </div>
  </div>
</template>

<script>
import ERow from '../../components/layout/components/Row'
import ECol from '../../components/layout/components/Col'
import {UList, UItem, UItemMain, UItemSide, UProgress} from 'uloc-vue'
import {ErpSelect, ErpInput} from 'uloc-vue-plugin-erp'
import {fileToBase64} from '@/utils/fileToBase64'
import fileType from '../../mixins/fileType'
import {datePtToEn} from "@/utils/date"

export default {
  name: 'WindowUploader',
  mixins: [fileType],
  components: {ECol, ERow, UList, UItem, UItemMain, UItemSide, UProgress, ErpSelect, ErpInput},
  props: {
    title: {
      default: 'Upload de Arquivos'
    },
    fileTypes: {
      type: String,
      default: '*'
    },
    enableTitleFile: {
      type: Boolean,
      default: false
    },
    enableValidade: {
      type: Boolean,
      default: false
    },
    typeFileList: {
      default: null
    },
    uploadService: {
      required: true,
      type: Function
    },
    successCallback: {
      required: false,
      type: Function
    },
    failureCallback: {
      required: false,
      type: Function
    },
    permissions: {
      required: false,
      type: Array,
      default: null,
      validator: v => v.every(opt => 'label' in opt && 'value' in opt)
    },
    tipoArquivo: {
      required: false,
      default: null
    }
  },
  data () {
    return {
      dropzone: false,
      tipos: [],
      files: [],
      tipoDefault: this.tipoArquivo
    }
  },
  computed: {
    existsFilesToUpload () {
      return this.files.some(file => !file.done)
    }
  },
  mounted () {
    this.loadTipos()
  },
  methods: {
    loadTipos () {
      this.typeFileList && this.typeFileList(200, 1) // TODO: NO LIMIT
        .then(response => {
          this.tipos = response.data.result ? response.data.result.map(item => {
            return {
              ...item,
              label: item.nome,
              value: item.id
            }
          }) : []
        })
        .catch((error) => {
          this.alertApiError(error)
        })
    },
    dropClickEvent (e) {
      !this.files.length && this.$refs.input.click()
    },
    inputListener (e) {
      console.log(e)
      this.dropzone = false
      // let files = []

      let bindFile = (file) => {
        if (!file.progress) {
          return {
            file: file,
            filename: file.name,
            done: false,
            copying: false,
            progress: 0,
            fail: false,
            success: false,
            tipo: this.tipoDefault,
            nome: null,
            validade: null,
            permissao: 0
          }
        }
        return file
      }

      // For drag via dataTransfer
      if (e.dataTransfer && e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === 'file') {
            this.files.push(bindFile(e.dataTransfer.items[i].getAsFile()))
          }
        }
      } else if (e.dataTransfer && e.dataTransfer.files) {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.files.push(bindFile(e.dataTransfer.files))
        }
      }

      // For input via target.files
      if (e.target.files && e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.files.push(bindFile(e.target.files[i]))
        }
      }

      console.log('Adicionando arquivos: ', this.files)
    },
    removeFile (file) {
      this.files.splice(this.files.indexOf(file), 1)
    },
    upload (file) {
      console.log('Fazendo upload ', file)
      file.done = true
      file.copying = true
      file.progress = 0
      if (file.validade) {
        file.validade = datePtToEn(file.validade)
      }
      this.$progress = setInterval(() => {
        let p = file.progress + Math.floor(Math.random() * 8 + 10)
        file.progress = p < 100 ? p : 100
        if (file.progress >= 100) {
          clearInterval(this.$progress)
        }
      }, 20)

      /*return new Promise((resolve, reject) => {
        setTimeout(() => {
          file.copying = false
          file.progress = 100
          file.success = true
          resolve('done!')
        }, 3000)
      });*/
      return new Promise((resolve, reject) => {
        fileToBase64(file.file)
          .then(_file => {
            this.uploadService({
              data: _file,
              ...file
            })
              .then(response => {
                console.log(response.data)
                this.successCallback && this.successCallback(response)
                file.copying = false
                file.progress = 100
                file.success = true
                resolve(true)
              })
              .catch(error => {
                this.failureCallback && this.failureCallback(error)
                file.done = false
                file.copying = false
                file.progress = 0
                file.success = false
                file.fail = true
                this.alertApiError(error)
                resolve(false)
              })
          })
      })
    },
    async iniciaEnvios () {
      console.log('Iniciando envio...')
      if (!this.files.length) {
        return
      }
      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].done) {
          await this.upload(this.files[i])
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .dropzone {
    &.dropzoneEnter {
      border #CCCCCC 4px dashed
      background #f3f4f8
    }
  }
</style>
